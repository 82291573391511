





































































































/* eslint-disable @typescript-eslint/no-inferrable-types */
import { Component } from 'vue-property-decorator';
import WidgetMixins from '../../mixins/WidgetMixins';
import { extend, ValidationObserver, ValidationProvider } from 'vee-validate';
import { required, email } from 'vee-validate/dist/rules';
import { mixins } from 'vue-class-component';
import { namespace } from 'vuex-class';

extend('email', {
  ...email,
  message: 'Invalid email address provided',
});
extend('required', {
  ...required,
  message: 'Field is required',
});

const authModule = namespace('auth');

@Component({
  components: {
    ValidationProvider,
    ValidationObserver,
  },
})
export default class Login extends mixins(WidgetMixins) {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  @authModule.Action('login') actionLogin: any;
  username: string = '';
  password: string = '';
  bg: string = require('@/assets/images/wave-haikei.svg');
  show: boolean = false;

  email: string = '';

  forgotPassword(): void {
    this.$store.dispatch('auth/forgotPassword', { email: this.email });
  }
}
